*,
*:before,
*:after {
    box-sizing: border-box;
}

html, body {
    height: 100%;
}

body {
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.4;
    padding: 0;
    margin: 0;
    background: #e6e7ee;
    color: #44476A;
    /* primary text #44476A */
    /* secondary text rgba(38,40,51,0.65)*/
}

#root {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

input:focus {
    outline: none;
}
